<template>
  <div class="flex flex-column flex-wrap evaluation">
    <div class="flex flex-between align-center evaluation-one">
      <div class="flex flex-left align-center evaluation-one-l">
        <img class="evaluation-one-l-logo" src="../../assets/img/brand/log.png" alt="">
        <div class="evaluation-one-l-info">
          <p class="evaluation-one-l-info-p1">品牌名称品牌名称</p>
          <p class="evaluation-one-l-info-p2">来自中国</p>
        </div>
      </div>
      <div class="flex flex-left align-center evaluation-one-r">
        <img src="../../assets/img/brand/auth.png" alt="">
        认证品牌
      </div>
    </div>
    <div class="flex flex-column flex-wrap evaluation-two">
      <ul class="flex flex-left evaluation-two-ul">
        <li class="evaluation-two-ul-li" v-for="(item, index) in 5" :key="item.id">
          <p>全部评价</p>
          <p>{{index}}</p>
        </li>
      </ul>
      <ul class="evaluation-two-list" style="display:none">
        <li class="flex flex-left evaluation-two-list-li">
          <div class="evaluation-two-list-li-headurl"></div>
          <div class="evaluation-two-list-li-info">
            <div class="flex flex-between evaluation-two-list-li-info-user">
              <div class="evaluation-two-list-li-info-user-l">
                <p>用户昵称</p>
                <p class="flex flex-left align-center">买家<i></i>2021.08.16</p>
              </div>
              <div class="evaluation-two-list-li-info-user-r">满意</div>
            </div>
            <div class="evaluation-two-list-li-info-msg">
              评价内容评价内容评价内容评价内容评价内容评价内容评价内容评价内容。
            </div>
            <ul class="flex flex-left evaluation-two-list-li-info-pic">
              <li class="evaluation-two-list-li-info-pic-img" v-for="item in 3" :key="item.id"></li>
            </ul>
          </div>
        </li>
      </ul>
      <!-- 暂无评价 -->
      <div class="evaluation-two-nodata">
        <div class="evaluation-two-nodata-main">
          <div class="evaluation-two-nodata-main-cont">
            <img src="../../assets/img/brand/nodata.png" alt="">
            <div>当前暂无评价</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
  @import "./index.scss";
</style>